import * as Sentry from "@sentry/react";
import currency from "currency.js";
import { ethers } from "ethers";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import infoIcon from "../../assets/svg/info.svg";
import { CollateralAssetResponse } from "../../codegen-api";
import { COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { SPACING } from "../../constants/design/spacing";
import { ONCHAIN_ESTIMATES } from "../../constants/onchainEstimates";
import { ConnectWalletContext } from "../../contexts/ConnectWalletContext";
import { ChainIdEnum, socketDepositChains } from "../../enums/chain";
import { ChainCodeErrorEnum } from "../../enums/rpcErrorCodes";
import { useClock } from "../../hooks/api/clock/useClock";
import { useYieldVault } from "../../hooks/api/yieldVault/useYieldVault";
import useDepositERC20OrETH from "../../hooks/contracts/useCollateralAssetOrETH";
import useDeposit, {
  IDepositERC20MultichainResponse,
} from "../../hooks/contracts/useDeposit";
import { useToast } from "../../hooks/toast";
import { useChangeText } from "../../hooks/useChangeText";
import useWallet, { ID_TO_CHAINS } from "../../hooks/wallet/useWallet";
import { ISignature } from "../../interfaces/Signing";
import {
  getAssetLogo,
  getAssetShortName,
  getCollateralAssets,
} from "../../utils/asset/assets";
import { CHAIN_EXPLORER_URLS } from "../../utils/chain";
import { ToastEnum, ToastStatusEnum } from "../../utils/toast";
import {
  supportedChainId,
  supportedSigningChainIds,
} from "../../utils/wallet/connectors";
import { BaseModal } from "../BaseModal";
import { Button, ButtonThemeEnum } from "../Buttons/styles";
import { InfoRow } from "../ConnectWalletModal/Deposit/style";
import SelectMultichainNetwork from "../SelectMultichainNetwork";
import { Chevron } from "../shared/Chevron/style";
import DepositPendingModal from "../shared/DepositPendingModal";
import { Input } from "../shared/Input";
import ModalOverlayInfo from "../shared/ModalOverlayInfo";
import { OverlayInfoContent } from "../shared/ModalOverlayInfo/style";
import { Spinner } from "../shared/Spinner";
import YieldVaultToggle from "../shared/YieldVaultToggle";
import {
  ApprovalSuccessMessage,
  ButtonsContainer,
  DepositAssetDropdownItem,
  DepositAssetDropdownTitle,
  ErrorMessage,
  InputContainer,
  InputError,
  InputLabel,
  MaxButton,
  Spacer,
} from "./style";
import {
  AllCollaterals,
  DepositWithdrawCollaterals,
  IsUSDC,
} from "../../constants/addresses";
import DropdownSimple from "../shared/DropdownSimple";

interface IDepositWithdrawModalProps {
  show?: boolean;
  onHide?: () => void;
  defaultAsset?: DepositWithdrawCollaterals;
}

function DepositModal({
  show,
  defaultAsset,
  onHide,
}: IDepositWithdrawModalProps) {
  const wallet = useWallet();
  const { getTimestamp } = useClock();

  const { account, provider, chainId, setChainToSwitch } = wallet;
  const { data: yieldVaultData } = useYieldVault();
  const [selectedDepositChain, setSelectedDepositChain] = useState(
    supportedSigningChainIds[0]
  );

  const [assetDropdownOpen, setAssetDropdownOpen] = useState(false);
  const { yieldVaultToggle, setYieldVaultToggle } =
    useContext(ConnectWalletContext);
  const [showReadyToDepositMessage, setShowReadyToDepositMessage] =
    useState(false);
  const [assetToDeposit, setAssetToDeposit] =
    useState<DepositWithdrawCollaterals>(DepositWithdrawCollaterals.Usdc);
  const [assetAllowance, setAssetAllowance] = useState<string>();

  const onSameNetwork = useMemo(
    () => chainId === selectedDepositChain,
    [chainId, selectedDepositChain]
  );

  const {
    l1Contract,
    l2Address,
    getAllowance,
    getAssetPermitSignature,
    approveAsset,
    ethBalance,
    userBalance,
    decimals,
  } = useDepositERC20OrETH(wallet, assetToDeposit);

  const {
    l1DepositHelper,
    socketHelper,
    approvalSpender,
    depositERC20,
    depositERC20WithPermit,
    depositETH,
    depositERC20OptimismOrArbitrum,
    depositETHOptimismOrArbitrum,
    depositFees,
  } = useDeposit(wallet, assetToDeposit);

  const { addToast } = useToast();
  const { t } = useTranslation("app", {
    keyPrefix: "DepositWithdrawModal.DepositModal",
  });
  const { t: formError } = useTranslation("formErrors");
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    control,
    setValue,
    clearErrors,
    trigger,
  } = useForm({
    mode: "onChange",
  });

  const pendingDepositTitles = useMemo(
    () => [
      t("initiating_deposit"),
      t("eta", {
        time: `${ONCHAIN_ESTIMATES.deposit.value} ${ONCHAIN_ESTIMATES.deposit.unit}`,
      }),
    ],
    [t]
  );

  const amount: string | undefined = useWatch({ control, name: "amount" });

  const [pendingDepositTxURL, setPendingDepositTxURL] = useState<
    string | undefined
  >();
  const pendingDepositTitle = useChangeText(
    pendingDepositTitles,
    5000,
    !!pendingDepositTxURL
  );

  const [approvedAmountAndSignature, setApprovedAmountAndSignature] = useState<{
    approvedAmount: string | undefined;
    signature: ISignature | undefined;
    deadline: number;
  }>({
    approvedAmount: undefined,
    signature: undefined,
    deadline: 0,
  });
  const [showHelpInfo, setShowHelpInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [depositWithdrawError, setDepositWithdrawError] = useState<
    string | undefined
  >();

  const notEnoughEthForFees = Boolean(
    amount && ethBalance.lt(depositFees || 0)
  );

  const depositAssetBalance = useMemo(
    () =>
      userBalance ? ethers.utils.formatUnits(userBalance, decimals) : undefined,
    [decimals, userBalance]
  );

  // When asset is not allowed, switch network and reset asset
  useEffect(() => {
    setSelectedDepositChain((chain) => {
      const allowedAssets = getCollateralAssets(chain, "deposit");
      if (!allowedAssets.includes(assetToDeposit)) {
        return supportedChainId;
      }
      return chain;
    });
  }, [assetToDeposit]);

  // When chain is switched, reset asset
  useEffect(() => {
    const allowedAssets = getCollateralAssets(selectedDepositChain, "deposit");
    if (defaultAsset && allowedAssets.includes(defaultAsset)) {
      setAssetToDeposit(defaultAsset);
    } else {
      setAssetToDeposit(allowedAssets[0] as DepositWithdrawCollaterals);
    }
  }, [defaultAsset, selectedDepositChain]);

  // When chain is switched, set selected deposit chain
  useEffect(() => {
    if (chainId && supportedSigningChainIds.includes(chainId)) {
      setSelectedDepositChain(chainId);
    }
  }, [chainId]);

  // When asset is changed, get allowance if needed
  useEffect(() => {
    if (approvalSpender && (!getAssetPermitSignature || !assetAllowance)) {
      const loadAssetAllowance = async () => {
        setLoading(true);
        try {
          const allowance = await getAllowance(approvalSpender);
          setAssetAllowance(allowance);
        } catch (error) {
          // Nothing
        } finally {
          setLoading(false);
        }
      };
      loadAssetAllowance();
    } else {
      setAssetAllowance("0");
    }
  }, [assetAllowance, getAllowance, getAssetPermitSignature, approvalSpender]);

  // Reset errors
  useEffect(() => {
    clearErrors();
    setShowHelpInfo(false);
    setDepositWithdrawError(undefined);
    setApprovedAmountAndSignature({
      approvedAmount: undefined,
      signature: undefined,
      deadline: 0,
    });
  }, [clearErrors, show, selectedDepositChain]);

  const hasValidSignature = useMemo(() => {
    if (
      amount &&
      amount === approvedAmountAndSignature.approvedAmount &&
      approvedAmountAndSignature.signature
    ) {
      return true;
    }
    return false;
  }, [
    amount,
    approvedAmountAndSignature.approvedAmount,
    approvedAmountAndSignature.signature,
  ]);

  const depositReady = useMemo(() => {
    if (!onSameNetwork) {
      return false;
    }

    // IF permit is allowed, check for valid signature.
    // ELSE, check for allowance
    if (getAssetPermitSignature) {
      return hasValidSignature;
    }
    return Number(assetAllowance) >= (Number(amount) || 0);
  }, [
    amount,
    assetAllowance,
    getAssetPermitSignature,
    hasValidSignature,
    onSameNetwork,
  ]);

  const estDepositTime = useMemo(() => {
    if (socketDepositChains.includes(selectedDepositChain)) {
      return `${ONCHAIN_ESTIMATES.socketDeposit.value} ${ONCHAIN_ESTIMATES.socketDeposit.unit}`;
    }
    return `${ONCHAIN_ESTIMATES.deposit.value} ${ONCHAIN_ESTIMATES.deposit.unit}`;
  }, [selectedDepositChain]);

  const submitButtonContent = useMemo(() => {
    if (!onSameNetwork) {
      return (
        <Button
          fullWidth
          buttonTheme={ButtonThemeEnum.HIGHLIGHT}
          type="button"
          onClick={() => setChainToSwitch(selectedDepositChain)}
        >
          {t("switch_to", { chain: ID_TO_CHAINS[selectedDepositChain] })}
        </Button>
      );
    }
    return (
      <>
        <Button
          fullWidth
          buttonTheme={ButtonThemeEnum.NEUTRAL2}
          type={"submit"}
          disabled={loading || !isValid}
        >
          {
            // eslint-disable-next-line no-nested-ternary
            loading ? (
              <Spinner />
            ) : // eslint-disable-next-line no-nested-ternary
            !depositReady ? (
              getAssetPermitSignature ? (
                t("permit")
              ) : (
                t("approve")
              )
            ) : (
              t("deposit")
            )
          }
        </Button>
        <Button
          buttonTheme={ButtonThemeEnum.NEUTRAL2}
          type={"button"}
          onClick={() => setShowHelpInfo(true)}
        >
          <img src={infoIcon} alt="info" />
        </Button>
      </>
    );
  }, [
    onSameNetwork,
    loading,
    isValid,
    depositReady,
    getAssetPermitSignature,
    t,
    selectedDepositChain,
    setChainToSwitch,
  ]);

  const messageContent = useMemo(() => {
    if (depositWithdrawError) {
      return <ErrorMessage>{depositWithdrawError}</ErrorMessage>;
    }
    if (notEnoughEthForFees) {
      return <ErrorMessage>{t("insufficient_eth_balance")}</ErrorMessage>;
    }
    if (depositReady && showReadyToDepositMessage) {
      return (
        <ApprovalSuccessMessage>{t("ready_to_deposit")}</ApprovalSuccessMessage>
      );
    }
    return undefined;
  }, [
    depositReady,
    depositWithdrawError,
    notEnoughEthForFees,
    showReadyToDepositMessage,
    t,
  ]);

  const onMaxClick = useCallback(() => {
    clearErrors("amount");
    setValue("amount", String(depositAssetBalance || 0));
    trigger("amount");
  }, [clearErrors, setValue, depositAssetBalance, trigger]);

  const handlePermitAsset = useCallback(
    async (amt: string) => {
      const helperAddress = socketDepositChains.includes(chainId as ChainIdEnum)
        ? socketHelper?.address
        : l1DepositHelper?.address;
      if (
        l1Contract?.address &&
        getAssetPermitSignature &&
        provider &&
        helperAddress
      ) {
        try {
          setLoading(true);
          // 1 hour from now
          const deadline = getTimestamp() + 60 * 60;
          const signature = await getAssetPermitSignature(
            helperAddress,
            amt,
            deadline
          );
          setApprovedAmountAndSignature({
            signature,
            deadline,
            approvedAmount: amt,
          });
          setShowReadyToDepositMessage(true);
        } catch (error: any) {
          setDepositWithdrawError("Approval failed");
          // eslint-disable-next-line no-console
          console.log({ error });
        } finally {
          setLoading(false);
        }
      }
    },
    [
      chainId,
      socketHelper?.address,
      l1DepositHelper?.address,
      l1Contract?.address,
      getAssetPermitSignature,
      provider,
      getTimestamp,
    ]
  );

  const handleApproveAsset = useCallback(
    async (amt: string) => {
      if (!approvalSpender) {
        return;
      }
      try {
        setLoading(true);
        await approveAsset(approvalSpender, amt);
        setAssetAllowance(amt);
        setShowReadyToDepositMessage(true);
      } catch (error: any) {
        setDepositWithdrawError(t("approval_failed"));
        // eslint-disable-next-line no-console
        console.log({ error });
      } finally {
        setLoading(false);
      }
    },
    [approveAsset, approvalSpender, t]
  );

  const handleDeposit = useCallback(async () => {
    if (account && amount) {
      let depositTx: ethers.ContractTransaction | undefined;

      // If permit is allowed, use that
      try {
        setLoading(true);

        if (!onSameNetwork) {
          throw Error("Wrong Network");
        }

        const insufficientLimit = (
          response: IDepositERC20MultichainResponse
        ) => {
          const chainEnum = chainId as ChainIdEnum;
          const otherNetworks = supportedSigningChainIds
            .filter((c) => c !== chainEnum)
            .map((c) => ID_TO_CHAINS[c]);
          const otherNetworksText =
            otherNetworks.length === 2
              ? otherNetworks.join(` ${t("or")} `)
              : otherNetworks.join(", ");
          const currentNetworkName = ID_TO_CHAINS[chainEnum];

          // No limit
          if (response.insufficientLimit?.isZero()) {
            setDepositWithdrawError(
              t("no_bridge_capacity", {
                currentChain: currentNetworkName,
                otherChains: otherNetworksText,
              })
            );
          } else {
            const limitStr = ethers.utils.formatUnits(
              response.insufficientLimit ?? "0",
              decimals
            );
            let text = t("insufficient_bridge_capacity", {
              chain: ID_TO_CHAINS[chainEnum],
            });
            text += t("reduce_deposit_size", {
              size: `${currency(limitStr).format({
                symbol: "",
              })} ${assetToDeposit}`,
              otherChains: otherNetworksText,
            });
            setDepositWithdrawError(text);
          }
        };

        // Deposit ETH
        if (assetToDeposit === CollateralAssetResponse.Weth) {
          const amtETH = ethers.utils.parseEther(amount);
          if (socketDepositChains.includes(chainId as ChainIdEnum)) {
            const response = await depositETHOptimismOrArbitrum(amtETH);
            if (response?.tx) {
              depositTx = response.tx;
            } else if (response?.insufficientLimit) {
              insufficientLimit(response);
            }
          } else {
            depositTx = await depositETH(amtETH);
          }
        } else {
          const depositAmtBN = ethers.utils.parseUnits(amount, decimals);

          if (
            socketDepositChains.includes(chainId as ChainIdEnum) &&
            l1Contract?.address
          ) {
            // Cross chain deposit
            const response = await depositERC20OptimismOrArbitrum(
              depositAmtBN,
              l1Contract.address,
              assetToDeposit,
              IsUSDC(assetToDeposit) ? yieldVaultToggle : false
            );
            if (response?.tx) {
              depositTx = response.tx;
            } else if (response?.insufficientLimit) {
              insufficientLimit(response);
            }
          } else if (l1Contract?.address && l2Address) {
            // If can permit deposit erc20, use that
            if (
              approvedAmountAndSignature.deadline &&
              approvedAmountAndSignature.signature
            ) {
              depositTx = await depositERC20WithPermit(
                l1Contract.address,
                l2Address,
                depositAmtBN,
                approvedAmountAndSignature.deadline,
                approvedAmountAndSignature.signature,
                IsUSDC(assetToDeposit) ? yieldVaultToggle : false
              );
            } else {
              // Else deposit erc20 with approval
              depositTx = await depositERC20(
                l1Contract.address,
                l2Address,
                depositAmtBN,
                IsUSDC(assetToDeposit) ? yieldVaultToggle : false
              );
            }
          }
        }

        // Only hides if a deposit tx was triggered
        if (depositTx) {
          addToast(
            {
              type: ToastEnum.SIMPLE,
              header: t("deposit_initiated"),
              subheader: t("deposit_initiated_desc", {
                amount: `${currency(amount).format({
                  symbol: "",
                })} ${getAssetShortName(assetToDeposit)}`,
                time: `${ONCHAIN_ESTIMATES.deposit.value} ${ONCHAIN_ESTIMATES.deposit.unit}`,
              }),
              status: ToastStatusEnum.SUCCESS,
            },
            10000
          );
        }
      } catch (error: any) {
        if (error.code === ChainCodeErrorEnum.CANCELLED) {
          setDepositWithdrawError(t("deposit_cancelled"));
        } else {
          setDepositWithdrawError(t("deposit_failed"));
          // eslint-disable-next-line no-console
          console.log({ error });
        }
      } finally {
        setLoading(false);
      }

      // Set pending deposit TX
      if (depositTx) {
        const explorerUrl = CHAIN_EXPLORER_URLS[chainId as ChainIdEnum];
        try {
          // wait for tx and then close
          setPendingDepositTxURL(`${explorerUrl}/tx/${depositTx.hash}`);
          await depositTx.wait(1);
        } catch (error) {
          console.log({ error });
          Sentry.captureException(Error("Deposit wait failed"), {
            extra: {
              error: JSON.stringify(error),
            },
          });
        } finally {
          setPendingDepositTxURL(undefined);
          onHide?.();
        }
      }
    }
  }, [
    account,
    amount,
    onSameNetwork,
    assetToDeposit,
    chainId,
    t,
    decimals,
    depositETHOptimismOrArbitrum,
    depositETH,
    l1Contract,
    l2Address,
    depositERC20OptimismOrArbitrum,
    yieldVaultToggle,
    approvedAmountAndSignature.deadline,
    approvedAmountAndSignature.signature,
    depositERC20WithPermit,
    depositERC20,
    addToast,
    onHide,
  ]);

  const onSubmit = useCallback(async () => {
    setDepositWithdrawError(undefined);

    if (account) {
      const amt = amount || "0";
      if (!depositReady) {
        if (getAssetPermitSignature) {
          handlePermitAsset(amt);
        } else {
          handleApproveAsset(amt);
        }
      } else {
        handleDeposit();
      }
    }
  }, [
    account,
    amount,
    depositReady,
    getAssetPermitSignature,
    handleApproveAsset,
    handleDeposit,
    handlePermitAsset,
  ]);

  if (pendingDepositTxURL) {
    return (
      <DepositPendingModal
        onHide={onHide}
        show={show}
        title={pendingDepositTitle}
        pendingDepositTxURL={pendingDepositTxURL}
      />
    );
  }

  return (
    <BaseModal
      onHide={onHide}
      show={show}
      style={{
        width: 310,
      }}
      title={t("deposit_asset", {
        asset: getAssetShortName(assetToDeposit, selectedDepositChain),
      })}
      titleStyle={{
        textTransform: "none",
      }}
      overflowShow
    >
      <ModalOverlayInfo
        show={showHelpInfo}
        onClose={() => setShowHelpInfo(false)}
        height="280px"
      >
        <OverlayInfoContent>
          <span>{t("deposits")}</span>
          <span>
            {t("how_deposits_work_subheader", {
              asset: getAssetShortName(assetToDeposit),
            })}
          </span>
        </OverlayInfoContent>
      </ModalOverlayInfo>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputContainer>
          <InputLabel>{t("deposit_from")}</InputLabel>
          <SelectMultichainNetwork
            selectedChain={selectedDepositChain}
            onSelectChain={setSelectedDepositChain}
          />
          <InputLabel>{t("amount")}</InputLabel>
          <Input
            placeholder="0.00"
            leftAccessory={
              <DropdownSimple<AllCollaterals>
                dropDirection={"down"}
                show={assetDropdownOpen}
                onToggle={setAssetDropdownOpen}
                toggleStyle={{
                  padding: 0,
                  paddingLeft: SPACING.two,
                  paddingRight: SPACING.two,
                }}
                title={
                  <DepositAssetDropdownTitle>
                    <img
                      src={getAssetLogo(assetToDeposit)}
                      width={24}
                      height={24}
                      alt={assetToDeposit}
                    />
                    <Chevron
                      size="small"
                      direction={assetDropdownOpen ? "up" : "down"}
                    />
                  </DepositAssetDropdownTitle>
                }
                items={getCollateralAssets(selectedDepositChain, "deposit")}
                getItemLabel={(v) => (
                  <DepositAssetDropdownItem selected={v === assetToDeposit}>
                    <img src={getAssetLogo(v)} width={24} height={24} alt={v} />
                    <span>{getAssetShortName(v, selectedDepositChain)}</span>
                  </DepositAssetDropdownItem>
                )}
                selectedItem={assetToDeposit}
                onSelectItem={(v) => {
                  setAssetToDeposit(v as DepositWithdrawCollaterals);
                }}
              />
            }
            rightAccessory={
              <MaxButton onClick={onMaxClick} type="button">
                {t("max")}
              </MaxButton>
            }
            type="number"
            disabled={loading}
            {...register("amount", {
              required: true,
              validate: {
                moreThanZero: (v) => parseFloat(v) > 0,
                lessThanEqualToBalance: (v) =>
                  parseFloat(v) <= parseFloat(depositAssetBalance || "0"),
              },
            })}
            error={Boolean(Object.keys(errors).length)}
          />
          {errors.amount?.type === "required" && (
            <InputError>{formError("amount.required")}</InputError>
          )}
          {errors.amount?.type === "moreThanZero" && (
            <InputError>{formError("amount.moreThanZero")}</InputError>
          )}
          {errors.amount?.type === "lessThanEqualToBalance" && (
            <InputError>
              {formError("amount.lessThanEqualToBalance")}
            </InputError>
          )}
        </InputContainer>
        {assetToDeposit !== CollateralAssetResponse.Weth && (
          <InfoRow error={notEnoughEthForFees}>
            <span>{t("wallet_balance")} (ETH)</span>
            <span>
              {onSameNetwork
                ? Number(ethers.utils.formatEther(ethBalance)).toFixed(4)
                : "---"}
            </span>
          </InfoRow>
        )}
        <InfoRow>
          <span>
            {t("wallet_balance")} (
            {getAssetShortName(assetToDeposit, selectedDepositChain)})
          </span>
          <span
            style={{
              color:
                errors.amount?.type === "lessThanEqualToBalance"
                  ? COLORS.negative.one
                  : TEXT_COLORS.one,
            }}
          >
            {onSameNetwork && depositAssetBalance
              ? currency(depositAssetBalance, { precision: 4 }).format({
                  symbol: "",
                })
              : "---"}
          </span>
        </InfoRow>
        <InfoRow>
          <span>{t("estimated_deposit_time")}</span>
          <span>{estDepositTime}</span>
        </InfoRow>
        {socketDepositChains.includes(selectedDepositChain as ChainIdEnum) && (
          <InfoRow error={notEnoughEthForFees}>
            <span>{t("bridge_fee")} (ETH)</span>
            <span>
              {depositFees
                ? Number(ethers.utils.formatEther(depositFees)).toFixed(6)
                : "---"}
            </span>
          </InfoRow>
        )}
        {IsUSDC(assetToDeposit) && !yieldVaultData?.is_paused && (
          <YieldVaultToggle
            isOn={yieldVaultToggle}
            onToggle={() => setYieldVaultToggle(!yieldVaultToggle)}
          />
        )}
        {/* temporarily disabled */}
        {/* {canReceiveTradingCredits && (
          <InfoRow>
            <span style={{ color: COLORS.blue.one }}>
              Deposit at least 100 USDC and receive an extra 25 USDC in trading
              credits
            </span>
          </InfoRow>
        )} */}
        <Spacer />
        {messageContent}
        <ButtonsContainer>{submitButtonContent}</ButtonsContainer>
      </form>
    </BaseModal>
  );
}

export default DepositModal;
