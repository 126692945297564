import { CollateralAssetResponse } from "../../codegen-api";
import { ChainIdEnum } from "../../enums/chain";
import contractAddresses from "./addresses.json";
import erc20Addresses from "./erc20Addresses.json";

export const DepositWithdrawCollaterals = {
  Usdc: "USDC",
  NativeUsdc: "Native USDC",
  Usdt: "USDT",
  Weth: "WETH",
  Wbtc: "WBTC",
  WeEth: "weETH",
} as const;

// eslint-disable-next-line no-redeclare
export type DepositWithdrawCollaterals =
  (typeof DepositWithdrawCollaterals)[keyof typeof DepositWithdrawCollaterals];

// Create a const of type CombinedCollaterals
export const AllCollaterals = {
  ...CollateralAssetResponse,
  ...DepositWithdrawCollaterals,
};

// eslint-disable-next-line no-redeclare
export type AllCollaterals =
  | CollateralAssetResponse
  | DepositWithdrawCollaterals;

// eslint-disable-next-line no-redeclare
export type AllDepositWithdrawAssets = AllCollaterals | "AEVO" | "RBN";

export const IsUSDC = (asset: AllCollaterals) =>
  asset === DepositWithdrawCollaterals.Usdc ||
  asset === DepositWithdrawCollaterals.NativeUsdc;

export const getAsset = (asset: AllCollaterals) =>
  asset === DepositWithdrawCollaterals.NativeUsdc
    ? DepositWithdrawCollaterals.Usdc
    : asset;

type ERC20AddressesForChainId = {
  [key in ChainIdEnum]?: (typeof erc20Addresses)["mainnet"] | undefined;
};

// On dev, ETH_MAINNET is LOCAL
export const ERC20_ADDRESSES: ERC20AddressesForChainId = {
  [ChainIdEnum.NONE]: undefined,
  [ChainIdEnum.ETH_MAINNET]: erc20Addresses.mainnet,
  [ChainIdEnum.SEPOLIA_TESTNET]: erc20Addresses.testnet,
  [ChainIdEnum.LOCAL_TESTNET]: erc20Addresses.local,

  [ChainIdEnum.OPTIMISM]: erc20Addresses.optimism,
  [ChainIdEnum.OPTIMISM_TESTNET]: erc20Addresses["optimism-testnet"],
  [ChainIdEnum.ARBITRUM]: erc20Addresses.arbitrum,
  [ChainIdEnum.ARBITRUM_TESTNET]: erc20Addresses["arbitrum-testnet"],

  [ChainIdEnum.ARBITRUM_LOCAL]: erc20Addresses["arbitrum-local"],

  // Base
  [ChainIdEnum.BASE]: erc20Addresses.base,
};

export const getCollateralERC20Addresses = (
  asset: AllDepositWithdrawAssets,
  chainId?: ChainIdEnum
) => {
  if (chainId && ERC20_ADDRESSES[chainId]) {
    switch (asset) {
      case CollateralAssetResponse.Usdc:
        return {
          l1: ERC20_ADDRESSES[chainId]?.usdc,
          l2: ERC20_ADDRESSES[chainId]?.l2usdc,
        };
      case DepositWithdrawCollaterals.NativeUsdc:
        return {
          l1: ERC20_ADDRESSES[chainId]?.["usdc-native"],
          l2: ERC20_ADDRESSES[chainId]?.l2usdc,
        };
      case CollateralAssetResponse.Usdt:
        return {
          l1: ERC20_ADDRESSES[chainId]?.usdt,
          l2: ERC20_ADDRESSES[chainId]?.l2usdt,
        };
      case CollateralAssetResponse.Weth:
        return {
          l1: ERC20_ADDRESSES[chainId]?.weth,
          l2: ERC20_ADDRESSES[chainId]?.l2weth,
        };
      case CollateralAssetResponse.Wbtc:
        return {
          l1: ERC20_ADDRESSES[chainId]?.wbtc,
          l2: ERC20_ADDRESSES[chainId]?.l2wbtc,
        };
      case CollateralAssetResponse.AeUsd:
        return {
          l1: undefined,
          l2: ERC20_ADDRESSES[chainId]?.aeusd,
        };
      case CollateralAssetResponse.WeEth:
        return {
          l1: ERC20_ADDRESSES[chainId]?.weeth,
          l2: ERC20_ADDRESSES[chainId]?.l2weeth,
        };
      case "AEVO":
        return {
          l1: ERC20_ADDRESSES[chainId]?.aevo,
          l2: undefined,
        };
      case "RBN":
        return {
          l1: ERC20_ADDRESSES[chainId]?.rbn,
          l2: undefined,
        };
    }
  }
  return {
    l1: undefined,
    l2: undefined,
  };
};

type ContractAddressesForChainId = {
  [key in ChainIdEnum]?: (typeof contractAddresses)["mainnet"] | undefined;
};
export const CONTRACT_ADDRESSES: ContractAddressesForChainId = {
  [ChainIdEnum.NONE]: undefined,
  [ChainIdEnum.ETH_MAINNET]: contractAddresses.mainnet,
  [ChainIdEnum.SEPOLIA_TESTNET]: contractAddresses.testnet,
  [ChainIdEnum.LOCAL_TESTNET]: contractAddresses.local,
  [ChainIdEnum.LINEA_MAINNET]: contractAddresses.linea,
  [ChainIdEnum.ARBITRUM]: contractAddresses.arbitrum,
};

type IMultichainContractAddresses = {
  [key in
    | ChainIdEnum.BASE
    | ChainIdEnum.OPTIMISM
    | ChainIdEnum.OPTIMISM_TESTNET
    | ChainIdEnum.ARBITRUM
    | ChainIdEnum.ARBITRUM_TESTNET
    | ChainIdEnum.ARBITRUM_LOCAL]: (typeof contractAddresses)["optimism"];
};

export const MULTICHAIN_CONTRACT_ADDRESSES: IMultichainContractAddresses = {
  [ChainIdEnum.OPTIMISM]: contractAddresses.optimism,
  [ChainIdEnum.OPTIMISM_TESTNET]: contractAddresses["optimism-testnet"],
  [ChainIdEnum.ARBITRUM]: contractAddresses.arbitrum,
  [ChainIdEnum.ARBITRUM_TESTNET]: contractAddresses["arbitrum-testnet"],
  [ChainIdEnum.ARBITRUM_LOCAL]: contractAddresses["arbitrum-local"],
  [ChainIdEnum.BASE]: contractAddresses.base,
};

export const getSocketConnectorAddress = (
  collateral: DepositWithdrawCollaterals,
  type: "deposit" | "withdrawal",
  chainId?: ChainIdEnum
) => {
  const multichainAddresses = chainId
    ? MULTICHAIN_CONTRACT_ADDRESSES[
        chainId as keyof typeof MULTICHAIN_CONTRACT_ADDRESSES
      ]
    : undefined;

  const connector =
    type === "deposit"
      ? multichainAddresses?.connector
      : multichainAddresses?.withdrawalConnector;

  switch (collateral) {
    case CollateralAssetResponse.Usdc:
      return connector?.usdc;
    case DepositWithdrawCollaterals.NativeUsdc:
      return connector?.["native-usdc"];
    case CollateralAssetResponse.Weth:
      return connector?.weth;
  }
  return undefined;
};

export type ICollateralAssetToAddresses = {
  [key in AllCollaterals]: string[];
};

export const addressToDepositCollateral: ICollateralAssetToAddresses = {
  [CollateralAssetResponse.Usdc]: [
    erc20Addresses.mainnet.usdc,
    erc20Addresses.testnet.usdc,
    erc20Addresses.local.usdc,
    erc20Addresses.mainnet.l2usdc,
    erc20Addresses.testnet.l2usdc,
    erc20Addresses.local.l2usdc,
  ],
  [DepositWithdrawCollaterals.NativeUsdc]: [
    erc20Addresses.mainnet["usdc-native"],
    erc20Addresses.testnet["usdc-native"],
    erc20Addresses.local["usdc-native"],
    erc20Addresses.mainnet["usdc-native"],
    erc20Addresses.testnet["usdc-native"],
    erc20Addresses.local["usdc-native"],
  ],
  [CollateralAssetResponse.Usdt]: [
    erc20Addresses.mainnet.usdt,
    erc20Addresses.testnet.usdt,
    erc20Addresses.local.usdt,
    erc20Addresses.mainnet.l2usdt,
    erc20Addresses.testnet.l2usdt,
    erc20Addresses.local.l2usdt,
  ],
  [CollateralAssetResponse.Weth]: [
    erc20Addresses.mainnet.weth,
    erc20Addresses.testnet.weth,
    erc20Addresses.local.weth,
    erc20Addresses.mainnet.l2weth,
    erc20Addresses.testnet.l2weth,
    erc20Addresses.local.l2weth,
  ],
  [CollateralAssetResponse.Wbtc]: [
    erc20Addresses.mainnet.wbtc,
    erc20Addresses.testnet.wbtc,
    erc20Addresses.local.wbtc,
    erc20Addresses.mainnet.l2wbtc,
    erc20Addresses.testnet.l2wbtc,
    erc20Addresses.local.l2wbtc,
  ],
  [CollateralAssetResponse.AeUsd]: [
    erc20Addresses.mainnet.aeusd,
    erc20Addresses.testnet.aeusd,
    erc20Addresses.local.aeusd,
    erc20Addresses.mainnet.aeusd,
    erc20Addresses.testnet.aeusd,
    erc20Addresses.local.aeusd,
  ],
  [CollateralAssetResponse.Sdai]: ["", "", "", "", "", ""],
  [CollateralAssetResponse.WeEth]: [
    erc20Addresses.mainnet.weeth,
    erc20Addresses.testnet.weeth,
    erc20Addresses.local.weeth,
    erc20Addresses.mainnet.l2weeth,
    erc20Addresses.testnet.l2weeth,
    erc20Addresses.local.l2weeth,
  ],
};
