import { AxiosError } from "axios";
import useSWRImmutable from "swr/immutable";
import { GetWeeth200Response } from "../../../codegen-api";
import { publicApi } from "../../../services/api/apiFetcher";
import { APIEndpointEnum } from "../../../enums/endpoint";
import { useLoggerMiddleware } from "../middleware/useLoggerMiddleware";

export const useWeETH = () => {
  const publicFetcher = publicApi();

  const swr = useSWRImmutable<GetWeeth200Response | undefined, AxiosError>(
    [APIEndpointEnum.WEETH],
    async () => (await (await publicFetcher.getWeeth())()).data,
    {
      use: [useLoggerMiddleware],
    }
  );

  return {
    ...swr,
  };
};
