import currency from "currency.js";
import moment from "moment";
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ReactComponent as Alert } from "../../assets/svg/alert.svg";
import {
  GetOrders200Response,
  InstrumentTypeResponse,
  OrderTypeResponse,
  SideResponse,
  Stop,
} from "../../codegen-api";
import { COLORS } from "../../constants/design/colors";
import { SPACING } from "../../constants/design/spacing";
import {
  MIN_ORDER_VALUE,
  getPerpsMaxLeverage,
} from "../../constants/precision/form";
import { MarketInstrumentContext } from "../../contexts/MarketInstrumentContext";
import { FormValidatorKeysEnum } from "../../enums/form";
import { useGetAccount } from "../../hooks/api/account/useGetAccount";
import { useMarginRequirements } from "../../hooks/api/margin/useMarginRequirements";
import { useOrder } from "../../hooks/api/order/useOrder";
import { useToast } from "../../hooks/toast";
import { useSFX } from "../../hooks/useSFX";
import { IPositionInfo, ITradeInfo } from "../../interfaces/TradeInfo";
import { AssetResponse } from "../../utils/asset";
import { nanosToSeconds } from "../../utils/date";
import { getValidTotalValue, roundToStepSize } from "../../utils/format";
import {
  getAssetFromSymbol,
  getContractPriceStep,
} from "../../utils/instruments";
import { getStopOrderName } from "../../utils/order";
import { startCase } from "../../utils/strings";
import { ToastEnum, ToastStatusEnum } from "../../utils/toast";
import { IBaseModalProps } from "../BaseModal";
import {
  PriceInput as OptionsPriceInput,
  SizeInput as OptionsSizeInput,
} from "../TradeForm/OptionsTradeForm/components/form";
import {
  PerpFormFieldKeyEnum,
  PriceInput as PerpsPriceInput,
  OrderSizeInput as PerpsSizeInput,
  TriggerPriceInput,
} from "../TradeForm/PerpsTradeForm/components/form";
import {
  Form,
  InfoRow,
  PlaceOrderButton,
  PositionChip,
  PositionInfoWrapper,
  Title,
  TradeInfoWrapper,
  Value,
} from "../TradeForm/style";
import { Spinner } from "../shared/Spinner";
import BaseDetailsModal from "../shared/ViewDetailsModal/BaseDetailsModal";
import { ModalErrorMessage } from "../shared/style";
import { EditOrderInfoTag } from "./style";
import { AccountStateEnum, AuthContext } from "../../contexts/AuthContext";
import { Button, ButtonThemeEnum } from "../Buttons/styles";
import { ConnectWalletContext } from "../../contexts/ConnectWalletContext";

interface IEditOrderModalProps extends IBaseModalProps {
  order?: GetOrders200Response;
}

/**
 * The modal responsible for removing orders
 */
function EditOrderModal({
  order,
  onHide,
  show,
  ...modalProps
}: IEditOrderModalProps) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [perpAsset, setPerpAsset] = useState<AssetResponse | undefined>();
  const { t: apiError } = useTranslation("apiErrors");
  const { accountSigningKeyState } = useContext(AuthContext);
  const { setShowConnectModal } = useContext(ConnectWalletContext);
  // DATA
  const { data: accountData } = useGetAccount();
  const { getMarketPrecision, activeOptionMarkets, activePerpMarkets } =
    useContext(MarketInstrumentContext);
  const asset = useMemo(
    () => getAssetFromSymbol(order?.instrument_name || ""),
    [order?.instrument_name]
  );
  const instrument = useMemo(() => {
    if (asset && order) {
      const markets =
        order.instrument_type === InstrumentTypeResponse.Option
          ? activeOptionMarkets
          : activePerpMarkets;
      return markets?.find((m) => m.underlying_asset === asset);
    }
    return undefined;
  }, [activeOptionMarkets, activePerpMarkets, asset, order]);
  const contractPriceStep = useMemo(
    () => getContractPriceStep(instrument),
    [instrument]
  );

  const unfilledAmount = useMemo(() => {
    const unfilled = Number(order?.amount) - Number(order?.filled);
    const priceStep = Number(contractPriceStep.price_step);
    return Math.max(priceStep, unfilled).toString();
  }, [contractPriceStep, order?.amount, order?.filled]);

  const { t: translateAll } = useTranslation();
  const { t } = useTranslation("app", {
    keyPrefix: "ConfirmationModal.EditOrderModal",
  });
  const { addToast } = useToast();
  const { editOrder } = useOrder();
  const { playSound } = useSFX();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    control,
    reset,
    trigger,
  } = useForm({
    mode: "onChange",
  });

  const amount = useWatch({ control, name: PerpFormFieldKeyEnum.AMOUNT });
  const price = useWatch({ control, name: PerpFormFieldKeyEnum.PRICE });
  const triggerPrice = useWatch({
    control,
    name: PerpFormFieldKeyEnum.TRIGGER_PRICE,
  });
  const isOption = useMemo(() => !!order?.option_type, [order?.option_type]);

  const contractSize = useMemo(
    () =>
      // Not option, and USDC is selected
      !isOption && !perpAsset ? Number(amount) / Number(price) : Number(amount),
    [amount, isOption, perpAsset, price]
  );
  const { data: marginData } = useMarginRequirements(
    {
      is_buy: order?.side === SideResponse.Buy,
      limit_price: price,
      amount: String(contractSize),
      instrument_id: Number(order?.instrument_id),
    },
    order?.order_id
  );

  // Update default asset
  useEffect(() => {
    setPerpAsset(instrument?.underlying_asset as AssetResponse);
  }, [instrument?.underlying_asset]);

  // Whenever the modal is presetned, reset state.
  useEffect(() => {
    if (show) {
      setErrorMessage(undefined);
    } else {
      // Reset form state when hiding
      reset();
    }
  }, [show, reset]);

  // Whenever an order is selected, updated the default value.
  useEffect(() => {
    if (show && order) {
      setValue(PerpFormFieldKeyEnum.TRIGGER_PRICE, order.trigger);
      setValue(PerpFormFieldKeyEnum.PRICE, order.price);
      setValue(
        PerpFormFieldKeyEnum.AMOUNT,
        !isOption && !perpAsset
          ? currency(Number(order.price) * Number(unfilledAmount)).value
          : unfilledAmount
      );
    }
  }, [order, perpAsset, setValue, show, isOption, unfilledAmount]);

  // Also trigger updates whenever price is changed
  useEffect(() => {
    if (price && amount) {
      trigger(PerpFormFieldKeyEnum.PRICE);
      trigger(PerpFormFieldKeyEnum.AMOUNT);
    }
  }, [price, amount, trigger]);

  // Also trigger updates whenever trigger price is changed
  useEffect(() => {
    if (triggerPrice) {
      trigger(PerpFormFieldKeyEnum.TRIGGER_PRICE);
    }
  }, [triggerPrice, trigger]);

  // Trigger checking whenever marginData changed
  useEffect(() => {
    if (marginData) {
      trigger(PerpFormFieldKeyEnum.PRICE);
      trigger(PerpFormFieldKeyEnum.AMOUNT);
    }
  }, [marginData, trigger]);

  const onEditOrder = useCallback(async () => {
    if (!order) {
      return;
    }

    try {
      setErrorMessage(undefined);
      setLoading(true);

      await editOrder(order.order_id, {
        instrument: Number(order.instrument_id),
        amount: String(contractSize),
        side: order.side,
        price: order.order_type === OrderTypeResponse.Limit ? price : undefined,
        orderType: order.order_type,
        stop: order.stop,
        trigger: triggerPrice,
      });

      // onHide?.();
      // TODO: - Show bid edited toasts
      playSound("order_placed");
      addToast(
        {
          type: ToastEnum.SIMPLE,
          header: t("order_edited_header"),
          subheader: t("order_edited_subheader"),
          status: ToastStatusEnum.SUCCESS,
        },
        4000
      );
      onHide?.();
    } catch (error: any) {
      setErrorMessage(apiError(error.message) || t("edit_order_error"));
    } finally {
      setLoading(false);
    }
  }, [
    order,
    editOrder,
    contractSize,
    price,
    triggerPrice,
    playSound,
    addToast,
    t,
    onHide,
    apiError,
  ]);

  const verifyOrderValueTooSmall = useCallback(
    (size: string) => {
      // Perps and asset is USD
      if (!isOption && !perpAsset) {
        return Number(size || 0) >= MIN_ORDER_VALUE;
      }

      // If is market order, use either trigger price of mark price
      const p =
        order?.order_type === OrderTypeResponse.Market
          ? order?.trigger || instrument?.mark_price
          : price;

      if (p && size) {
        return Number(size || 0) * Number(p || 0) >= MIN_ORDER_VALUE;
      }
      return true;
    },
    [
      instrument?.mark_price,
      isOption,
      order?.order_type,
      order?.trigger,
      perpAsset,
      price,
    ]
  );

  const verifyNotEnoughBalance = useCallback(() => {
    if (marginData) {
      return (
        Number(marginData.initial_margin) <=
        Number(accountData?.available_margin || 0)
      );
    }
    return true;
  }, [marginData, accountData?.available_margin]);

  // Account max leverage takes precedence before asset max leverage
  const maxLeverage = useMemo(() => {
    const accountLeverage = accountData?.leverages?.find(
      (lev) => lev.instrument_id === instrument?.instrument_id
    )?.leverage;
    const lev = accountLeverage
      ? Number(accountLeverage)
      : Number(instrument?.max_leverage);
    return lev || 0;
  }, [accountData?.leverages, instrument]);

  const buyingPower = useMemo(() => {
    if (accountData) {
      return (
        Number(accountData.available_margin) *
        (maxLeverage || getPerpsMaxLeverage(instrument?.instrument_name))
      );
    }
    return 0;
  }, [accountData, instrument?.instrument_name, maxLeverage]);

  const totalValue = useMemo(() => {
    // If is market order, use either trigger price of mark price
    const p =
      order?.order_type === OrderTypeResponse.Market
        ? order?.trigger || instrument?.mark_price
        : price;

    if (Number(amount) && Number(p)) {
      return contractSize * Number(p);
    }
    return 0;
  }, [
    order?.order_type,
    order?.trigger,
    instrument?.mark_price,
    price,
    amount,
    contractSize,
  ]);

  // ERRORS
  const orderValueTooSmall = useMemo(() => {
    if (errors?.amount?.type === FormValidatorKeysEnum.orderValueTooSmall) {
      return true;
    }
    return false;
  }, [errors?.amount?.type]);

  const notEnoughBalanceError = useMemo(() => {
    if (errors?.amount?.type === FormValidatorKeysEnum.notEnoughBalance) {
      return true;
    }
    return false;
  }, [errors?.amount?.type]);

  const tradeInfo = useMemo<ITradeInfo[]>(() => {
    if (!order) {
      return [];
    }

    // If is option, buy/sell shows different info
    if (isOption) {
      if (order.side === SideResponse.Buy) {
        // LIMIT BUY ORDER
        return [
          {
            title: t("total"),
            value: totalValue
              ? currency(totalValue, {
                  precision: getMarketPrecision(
                    asset,
                    instrument?.instrument_type
                  ).price_precision,
                }).format()
              : "---",
            warningOrError:
              notEnoughBalanceError || orderValueTooSmall ? "error" : undefined,
          },
          {
            title: t("amount_filled"),
            value: `${order.filled} / ${order.amount}`,
          },
        ];
      }

      return [
        {
          title: t("premiums_earned"),
          value: totalValue
            ? currency(totalValue, {
                precision: getMarketPrecision(
                  asset,
                  instrument?.instrument_type
                ).price_precision,
              }).format()
            : "---",
        },
        {
          title: t("margin_required"),
          value: marginData?.initial_margin
            ? currency(marginData?.initial_margin).format()
            : "---",
          warningOrError:
            notEnoughBalanceError || orderValueTooSmall ? "error" : undefined,
        },
        {
          title: t("amount_filled"),
          value: `${order.filled} / ${order.amount}`,
        },
      ];
    }

    // If not option, just return total and amount
    return [
      {
        title: t("total"),
        value: totalValue
          ? currency(totalValue, {
              precision: getMarketPrecision(asset, instrument?.instrument_type)
                .price_precision,
            }).format()
          : "---",
        warningOrError:
          notEnoughBalanceError || orderValueTooSmall ? "error" : undefined,
      },
    ];
  }, [
    order,
    isOption,
    totalValue,
    getMarketPrecision,
    asset,
    instrument?.instrument_type,
    notEnoughBalanceError,
    orderValueTooSmall,
    marginData?.initial_margin,
    t,
  ]);
  const triggerPriceValidateFn = useMemo(() => {
    if (!order?.stop) {
      return {
        [FormValidatorKeysEnum.triggerPriceBelowMark]: () => true,
        [FormValidatorKeysEnum.triggerPriceAboveMark]: () => true,
      };
    }

    // If BUY STOP LOSS or SELL TAKE PROFIT, trigger price must be higher than mark
    if (
      (order?.side === SideResponse.Buy && order?.stop === Stop.StopLoss) ||
      (order?.side === SideResponse.Sell && order?.stop === Stop.TakeProfit)
    ) {
      return {
        [FormValidatorKeysEnum.triggerPriceBelowMark]: () => true,
        [FormValidatorKeysEnum.triggerPriceAboveMark]: (v: string) =>
          parseFloat(v) >= Number(instrument?.mark_price || 0),
      };
    }
    // If SELL STOP LOSS or BUY TAKE PROFIT, trigger price must be lower than mark
    return {
      [FormValidatorKeysEnum.triggerPriceBelowMark]: (v: string) =>
        parseFloat(v) <= Number(instrument?.mark_price || 0),
      [FormValidatorKeysEnum.triggerPriceAboveMark]: () => true,
    };
  }, [instrument?.mark_price, order?.side, order?.stop]);

  const positionInfo = useMemo<IPositionInfo[]>(() => {
    if (!order) {
      return [];
    }
    const currentPosition = (accountData?.positions ?? []).find(
      (position) => position.instrument_name === order.instrument_name
    );

    const buyingPowerInfo: IPositionInfo | undefined = !isOption
      ? {
          title: t("buying_power"),
          value: currency(buyingPower).format(),
          warningOrError: notEnoughBalanceError ? "error" : undefined,
          showErrorIcon: true,
        }
      : undefined;

    const liquidationPriceInfo: IPositionInfo | undefined = !isOption
      ? {
          title: t("liquidation_price"),
          value: marginData?.liquidation_price
            ? currency(marginData.liquidation_price).format()
            : "---",
        }
      : undefined;

    return [
      {
        title: t("available_margin"),
        value: accountData?.available_margin
          ? currency(accountData.available_margin).format()
          : "---",
        side: undefined,
        warningOrError:
          notEnoughBalanceError && !buyingPowerInfo ? "error" : undefined,
        showErrorIcon: true,
      },
      ...(buyingPowerInfo ? [buyingPowerInfo] : []),
      {
        title: `${t("position")} (${asset})`,
        value: currentPosition
          ? Number(currentPosition.amount).toFixed(2)
          : "---",
        side: currentPosition?.side,
      },
      ...(liquidationPriceInfo ? [liquidationPriceInfo] : []),
    ];
  }, [
    accountData,
    asset,
    buyingPower,
    isOption,
    marginData?.liquidation_price,
    notEnoughBalanceError,
    order,
    t,
  ]);

  if (!order) {
    return null;
  }

  return (
    <BaseDetailsModal
      instrument={
        order
          ? {
              instrument_name: order.instrument_name,
              expiry: order.expiry,
              strike: order.strike,
              markPrice: instrument?.mark_price || "0",
            }
          : undefined
      }
      show={!!show}
      onHide={onHide}
      {...modalProps}
    >
      <Form style={{ padding: 0 }} onSubmit={handleSubmit(onEditOrder)}>
        <EditOrderInfoTag
          color={
            order.side === SideResponse.Buy
              ? COLORS.positive.one
              : COLORS.negative.one
          }
          background={
            order.side === SideResponse.Buy
              ? COLORS.positive.five
              : COLORS.negative.five
          }
        >
          <div>{t(order.side)}</div>
          {order.option_type && order.expiry && order.strike ? (
            <>
              <div>{startCase(t(order.option_type))}</div>
              <div>
                {moment.unix(nanosToSeconds(order.expiry)).format("DD MMM YY")}
              </div>
              <div>{currency(order.strike).format()}</div>
            </>
          ) : (
            <div>
              {order.stop
                ? getStopOrderName(translateAll, order.order_type, true)
                : t(order.order_type)}
            </div>
          )}
        </EditOrderInfoTag>
        {isOption ? (
          <>
            <OptionsPriceInput
              minPrice={contractPriceStep.price_step}
              register={register("price", {
                disabled: loading,
                required: true,
                validate: {
                  [FormValidatorKeysEnum.moreThanZero]: (v) =>
                    parseFloat(v) > 0,
                  [FormValidatorKeysEnum.decimalsTooSmall]: (v) =>
                    roundToStepSize(
                      v,
                      contractPriceStep.price_step,
                      contractPriceStep.price_precision
                    ) >=
                    1 / 10 ** contractPriceStep.price_precision,
                },
                onBlur(event) {
                  setValue(
                    "price",
                    roundToStepSize(
                      parseFloat(event.target.value),
                      contractPriceStep.price_step,
                      contractPriceStep.price_precision
                    ).toString()
                  );
                },
              })}
              errors={errors}
            />
            <OptionsSizeInput
              minAmountSize={contractPriceStep.amount_step || 0}
              register={register("amount", {
                disabled: loading,
                required: true,
                validate: {
                  [FormValidatorKeysEnum.moreThanZero]: (v) =>
                    parseFloat(v) > 0,
                  [FormValidatorKeysEnum.notEnoughBalance]:
                    verifyNotEnoughBalance,
                  [FormValidatorKeysEnum.orderValueTooSmall]:
                    verifyOrderValueTooSmall,
                  [FormValidatorKeysEnum.decimalsTooSmall]: (v) =>
                    roundToStepSize(
                      v,
                      contractPriceStep.amount_step,
                      contractPriceStep.amount_precision
                    ) >=
                    1 / 10 ** contractPriceStep.amount_precision,
                },
                onBlur(event) {
                  setValue(
                    "amount",
                    roundToStepSize(
                      parseFloat(event.target.value),
                      contractPriceStep.amount_step,
                      contractPriceStep.amount_precision
                    ).toString()
                  );
                },
              })}
              style={{ paddingBottom: 0 }}
              errors={errors}
            />
          </>
        ) : (
          <>
            {/* Only show trigger price if is stop order */}
            {order?.trigger && (
              <TriggerPriceInput
                placeholder={currency(order.trigger).format()}
                markPrice={
                  instrument?.mark_price
                    ? Number(instrument.mark_price).toFixed(
                        contractPriceStep.price_precision
                      )
                    : undefined
                }
                minPrice={contractPriceStep.price_step}
                register={register(PerpFormFieldKeyEnum.TRIGGER_PRICE, {
                  disabled: loading,
                  required: true,
                  validate: {
                    ...triggerPriceValidateFn,
                    [FormValidatorKeysEnum.moreThanZero]: (v) =>
                      parseFloat(v) > 0,
                    [FormValidatorKeysEnum.decimalsTooSmall]: (v) =>
                      roundToStepSize(
                        Number(v),
                        contractPriceStep.price_step,
                        contractPriceStep.price_precision
                      ) >=
                      1 / 10 ** contractPriceStep.price_precision,
                  },
                  onBlur(event) {
                    setValue(
                      PerpFormFieldKeyEnum.TRIGGER_PRICE,
                      roundToStepSize(
                        parseFloat(event.target.value),
                        contractPriceStep.price_step,
                        contractPriceStep.price_precision
                      ).toString()
                    );
                  },
                })}
                errors={errors}
              />
            )}
            {/* Only show limit price if is limit order */}
            {order?.order_type === OrderTypeResponse.Limit && (
              <PerpsPriceInput
                placeholder={order?.price}
                minPrice={contractPriceStep.price_step}
                register={register("price", {
                  disabled: loading,
                  required: true,
                  validate: {
                    [FormValidatorKeysEnum.moreThanZero]: (v) =>
                      parseFloat(v) > 0,
                    [FormValidatorKeysEnum.decimalsTooSmall]: (v) =>
                      roundToStepSize(
                        v,
                        contractPriceStep.price_step,
                        contractPriceStep.price_precision
                      ) >=
                      1 / 10 ** contractPriceStep.price_precision,
                  },
                  onBlur(event) {
                    setValue(
                      PerpFormFieldKeyEnum.PRICE,
                      roundToStepSize(
                        parseFloat(event.target.value),
                        contractPriceStep.price_step,
                        contractPriceStep.price_precision
                      ).toString()
                    );
                  },
                })}
                errors={errors}
              />
            )}
            <PerpsSizeInput
              placeholder={unfilledAmount}
              minAmountSize={
                perpAsset
                  ? contractPriceStep.amount_step
                  : contractPriceStep.price_step
              }
              underlyingAsset={instrument?.underlying_asset as AssetResponse}
              selectedAsset={perpAsset}
              onChangeAsset={setPerpAsset}
              register={register("amount", {
                disabled: loading,
                required: true,
                validate: {
                  [FormValidatorKeysEnum.moreThanZero]: (v) =>
                    parseFloat(v) > 0,
                  [FormValidatorKeysEnum.notEnoughBalance]:
                    verifyNotEnoughBalance,
                  [FormValidatorKeysEnum.orderValueTooSmall]:
                    verifyOrderValueTooSmall,
                  [FormValidatorKeysEnum.decimalsTooSmall]: (v) => {
                    if (perpAsset) {
                      return (
                        roundToStepSize(
                          v,
                          contractPriceStep.amount_step,
                          contractPriceStep.amount_precision
                        ) >=
                        1 / 10 ** contractPriceStep.amount_precision
                      );
                    }
                    return (
                      roundToStepSize(
                        v,
                        contractPriceStep.price_step,
                        contractPriceStep.price_precision
                      ) >=
                      1 / 10 ** contractPriceStep.price_precision
                    );
                  },
                },
                onBlur(event) {
                  setValue(
                    PerpFormFieldKeyEnum.AMOUNT,
                    perpAsset
                      ? roundToStepSize(
                          parseFloat(event.target.value),
                          contractPriceStep.amount_step,
                          contractPriceStep.amount_precision
                        ).toString()
                      : getValidTotalValue(
                          Number(price),
                          event.target.value,
                          contractPriceStep.amount_step,
                          contractPriceStep.amount_precision
                        )
                  );
                },
              })}
              style={{ paddingBottom: 0 }}
              errors={errors}
            />
          </>
        )}
        <TradeInfoWrapper>
          {tradeInfo.map((info) => (
            <InfoRow
              key={`${info.title}-${info.value}}`}
              warningOrError={info.warningOrError}
            >
              <Title>
                {info.title}
                {Boolean(info.warningOrError && info.showErrorIcon) && (
                  <Alert
                    style={{
                      stroke:
                        info.warningOrError === "warning"
                          ? COLORS.system.one
                          : COLORS.negative.one,
                      marginLeft: `${SPACING.one}px`,
                      marginTop: `-${SPACING.one / 2}px`,
                    }}
                  />
                )}
              </Title>
              <Value>{info.value}</Value>
            </InfoRow>
          ))}
        </TradeInfoWrapper>

        <PositionInfoWrapper>
          {positionInfo.map((info) => (
            <InfoRow
              key={`${info.title}-${info.value}}`}
              warningOrError={info.warningOrError}
            >
              <Title>
                {info.title}
                {Boolean(info.warningOrError && info.showErrorIcon) && (
                  <Alert
                    style={{
                      stroke:
                        info.warningOrError === "warning"
                          ? COLORS.system.one
                          : COLORS.negative.one,
                      marginLeft: `${SPACING.one}px`,
                      marginTop: `-${SPACING.one / 2}px`,
                    }}
                  />
                )}
                {info.side && (
                  <PositionChip type={info.side}>
                    {info.side === SideResponse.Buy ? t("long") : t("short")}
                  </PositionChip>
                )}
              </Title>
              <Value>{info.value}</Value>
            </InfoRow>
          ))}
        </PositionInfoWrapper>

        {accountSigningKeyState !== AccountStateEnum.OK ? (
          <Button
            buttonTheme={ButtonThemeEnum.HIGHLIGHT}
            fullWidth
            style={{ marginBottom: 0 }}
            onClick={() => setShowConnectModal(true)}
          >
            {t("complete_sign_in")}
          </Button>
        ) : (
          <PlaceOrderButton
            disabled={loading || Object.values(errors).length > 0}
            type={"submit"}
            side={order.side}
            style={{ marginBottom: 0 }}
          >
            {
              // eslint-disable-next-line no-nested-ternary
              !loading ? (
                t("update_order")
              ) : (
                <Spinner
                  color={
                    order.side === SideResponse.Buy
                      ? COLORS.positive.one
                      : COLORS.negative.one
                  }
                />
              )
            }
          </PlaceOrderButton>
        )}

        {errorMessage && <ModalErrorMessage>{errorMessage}</ModalErrorMessage>}
      </Form>
    </BaseDetailsModal>
  );
}

export default memo(EditOrderModal);
