import { useMemo } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import MainPage from "./MainPage";
import useScreenSize from "../../hooks/screenSize/useScreenSize";
import { LeaderboardWrapper } from "./style";
import { secondsToNanos } from "../../utils/date";
import Campaign from "./Campaign";
import EigenCampaign from "./EigenCampaign";
import { getCurrentEpochTimes } from "../../utils/math";

export enum CampaignEnum {
  EIGEN = "eigen",
  WSB = "wsb-eigen",
  USDT = "ath-usdt",
}

export interface ICampaignDetail {
  startTime: number;
  endTime: number;
  epoch1StartTime: number;
  epoch1EndTime: number;
  epoch2EndTime: number;
  title: string;
  campaignName: string;
  tradingRewards: number;
  pnlRewards: number;
  rewards: number;
  epoch: number;
  asset: string;
  minPerpVolume: string;
  placings: number;
}

export type CampaignDetailsType = {
  [key in CampaignEnum]: ICampaignDetail;
};

export const wsbEpoch1StartTime = secondsToNanos(1727856000); // 2nd October
export const wsbEpoch1EndTime = secondsToNanos(1729065600); // 16th October
export const wsbEpoch2EndTime = secondsToNanos(1730275200); // 30th October
export const usdtEpoch1StartTime = secondsToNanos(1731571200); // 14th November
export const usdtEpoch1EndTime = secondsToNanos(1732780800); // 28th November
export const usdtEpoch2EndTime = secondsToNanos(1733990400); // 12th December
export const eigenEpoch1StartTime = secondsToNanos(1737077400); // 17th January
export const eigenEpoch1EndTime = secondsToNanos(1737705600); // 24th January
export const eigenEpoch2EndTime = secondsToNanos(1738310400); // 31st January
const currentTime = Date.now() * 1e6; // Convert current time to nanoseconds

const wsbStartTime =
  currentTime >= wsbEpoch1EndTime ? wsbEpoch1EndTime : wsbEpoch1StartTime;

const wsbEndTime =
  currentTime >= wsbEpoch1EndTime ? wsbEpoch2EndTime : wsbEpoch1EndTime;

const usdtStartTime =
  currentTime >= usdtEpoch1EndTime ? usdtEpoch1EndTime : usdtEpoch1StartTime;

const usdtEndTime =
  currentTime >= usdtEpoch1EndTime ? usdtEpoch2EndTime : usdtEpoch1EndTime;

export const wsbCurrentEpoch =
  currentTime >= wsbEpoch1EndTime && currentTime >= wsbEpoch1StartTime ? 2 : 1;
export const usdtCurrentEpoch =
  currentTime >= usdtEpoch1EndTime && currentTime >= usdtEpoch1StartTime
    ? 2
    : 1;

export const eigenCurrentEpochTimes = getCurrentEpochTimes();

export const CampaignDetails: CampaignDetailsType = {
  [CampaignEnum.EIGEN]: {
    startTime: eigenCurrentEpochTimes.epochStartTimeNano,
    endTime: eigenCurrentEpochTimes.epochEndTimeNano,
    epoch1StartTime: 0,
    epoch1EndTime: 0,
    epoch2EndTime: 0,
    title: "EIGEN Campaign",
    campaignName: CampaignEnum.EIGEN,
    tradingRewards: 500000,
    pnlRewards: 0,
    rewards: 500000,
    epoch: eigenCurrentEpochTimes.currentEpoch,
    asset: "EIGEN",
    minPerpVolume: "$500k",
    placings: 10,
  },
  [CampaignEnum.WSB]: {
    startTime: wsbStartTime,
    endTime: wsbEndTime,
    epoch1StartTime: wsbEpoch1StartTime,
    epoch1EndTime: wsbEpoch1EndTime,
    epoch2EndTime: wsbEpoch2EndTime,
    title: "WSB Campaign",
    campaignName: CampaignEnum.WSB,
    tradingRewards: 14000,
    pnlRewards: 11000,
    rewards: 25000,
    epoch: wsbCurrentEpoch,
    asset: "EIGEN",
    minPerpVolume: "$1M",
    placings: 10,
  },
  [CampaignEnum.USDT]: {
    startTime: usdtStartTime,
    epoch1StartTime: usdtEpoch1StartTime,
    epoch1EndTime: usdtEpoch1EndTime,
    endTime: usdtEndTime,
    epoch2EndTime: usdtEpoch2EndTime,
    title: "USDT Campaign",
    campaignName: CampaignEnum.USDT,
    tradingRewards: 25000,
    pnlRewards: 25000,
    rewards: 50000,
    epoch: usdtCurrentEpoch,
    asset: "USDT",
    minPerpVolume: "$200k",
    placings: 20,
  },
};

type CampaignComponentType = {
  [key in CampaignEnum]: JSX.Element;
};

const CampaignComponent: CampaignComponentType = {
  [CampaignEnum.EIGEN]: (
    <EigenCampaign campaignDetails={CampaignDetails[CampaignEnum.EIGEN]} />
  ),
  [CampaignEnum.WSB]: (
    <Campaign campaignDetails={CampaignDetails[CampaignEnum.WSB]} />
  ),
  [CampaignEnum.USDT]: (
    <Campaign campaignDetails={CampaignDetails[CampaignEnum.USDT]} />
  ),
};

function CampaignsPage() {
  const { isMobileScreen } = useScreenSize();

  const location = useLocation();

  // Get active page from URL
  // Extract the active campaign from the URL path
  const activePage = useMemo(() => {
    const splitPath = location.pathname.split("/campaign/")[1];
    return splitPath || undefined; // Set activePage to undefined if no campaign is found
  }, [location]);

  const pages = useMemo(() => {
    // Add the main leaderboard page
    const pageList = [
      {
        title: "Main Page",
        path: "*",
        redirect: "campaign",
        element: <MainPage />,
        isActive: !activePage,
      },
    ];

    Object.keys(CampaignDetails).forEach((campaignKey) => {
      const campaign = CampaignDetails[campaignKey as CampaignEnum];
      pageList.push({
        title: campaign.title,
        path: `/${campaignKey}`,
        redirect: `campaign/${campaignKey}`,
        element: CampaignComponent[campaignKey as CampaignEnum],
        isActive: activePage === campaignKey,
      });
    });

    return pageList;
  }, [activePage]);

  return (
    <LeaderboardWrapper isMobileScreen={isMobileScreen}>
      <Routes>
        {pages.map((page) => (
          <Route key={page.path} path={page.path} element={page.element} />
        ))}
        <Route path="*" element={<Navigate to="/campaign" />} />
      </Routes>
    </LeaderboardWrapper>
  );
}

export default CampaignsPage;
