/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTable, useSortBy } from "react-table";
import { useNavigate } from "react-router-dom";
import currency from "currency.js";
import { ethers } from "ethers";
import {
  LeaderboardTableRow,
  LeaderboardTableWrapper,
  LeaderboardHeader,
  BackButtonWrapper,
  LogoContainer,
  SOLVideoContainer,
  DescriptionContainer,
  Countdown,
  CountdownContainer,
  List,
  StyledBody,
  ListItem,
} from "./style";
import BackgroundVideoFX from "../../components/shared/BackgroundVideoFX/BackgroundVideoFX";
import { useGetAccount } from "../../hooks/api/account/useGetAccount";
import { useGetLeaderboard } from "../../hooks/api/leaderboard/useGetLeaderboard";
import { GetLeaderboard200ResponseLeaderboardDaily } from "../../codegen-api";
import { ITableColumn } from "../../interfaces/Table/TableColumn";
import { EmptyContentContainer } from "../../components/PortfolioSettings/Table/style";
import { SpinnerContainerWrapper } from "../../components/shared/Spinner/style";
import { DefaultCellForColumn } from "../../components/shared/Table/DefaultCellForColumn";
import { DefaultHeaderForColumn } from "../../components/shared/Table/DefaultHeaderForColumn";
import { Align, TableHeaderCell } from "../../components/shared/Table/style";
import { COLORS, TEXT_COLORS } from "../../constants/design/colors";
import useScreenSize from "../../hooks/screenSize/useScreenSize";
import { Spinner } from "../../components/shared/Spinner";
import { useGetAccountStatistics } from "../../hooks/api/accountStatistics/useGetAccountStatistics";
import { AccountStateEnum, AuthContext } from "../../contexts/AuthContext";
import { ReactComponent as LeftArrow } from "../../assets/svg/arrow-left.svg";
import { getAssetLogo } from "../../utils/asset/assets";
import { PageEndpointEnum } from "../../enums/endpoint";
import { useCountdown } from "./useCountdown";
import { CampaignEnum, ICampaignDetail } from ".";
import {
  Body,
  Data,
  FONT_SIZE,
  Header,
  Label,
} from "../../constants/design/fontSize";
import { FlexWrapper, SPACING } from "../../constants/design/spacing";
import EigenTermsModal from "./EigenTermsModal";
import SegmentControl from "../../components/shared/SegmentedControl";
import ExternalLinkText from "../../components/ExternalLinkText";
import { useWeETH } from "../../hooks/api/weETH/useWeETH";

enum LeaderboardFilterEnum {
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
  ALL_TIME = "all_time",
}

enum CampaignSectionEnum {
  DEPOSIT = "deposit",
  TRADE = "trade",
}

interface ICampaignProps {
  campaignDetails: ICampaignDetail;
}

function EigenCampaign({ campaignDetails }: ICampaignProps) {
  const { data: accountData } = useGetAccount();
  const { isMobileScreen } = useScreenSize();
  const { t: campaignTranslations } = useTranslation("app", {
    keyPrefix: "pages.CampaignsPage",
  });
  const { t } = useTranslation("app", {
    keyPrefix: "pages.CampaignsPage.EigenCampaign",
  });

  const { campaignName, epoch, asset, placings, startTime, endTime } =
    campaignDetails;

  const [campaignSection, setCampaignSection] = useState<CampaignSectionEnum>(
    CampaignSectionEnum.DEPOSIT
  );

  const { data: weETHData } = useWeETH();

  const { data: volumeLeaderboard, isValidating: volumeLeaderboardLoading } =
    useGetLeaderboard({
      limit: placings,
      sort: "PerpVolume",
      campaignName: "",
      startTime,
      endTime,
    });

  const { data: accountStatistics, isValidating: accountStatisticsLoading } =
    useGetAccountStatistics({
      campaignName: "",
      startTime,
      endTime,
    });

  const { accountApiKeyState } = useContext(AuthContext);
  const navigate = useNavigate();

  const [showTermsModal, setShowTermsModal] = useState<boolean>(false);
  const countdownMapping = useCountdown();
  const { days, hours, minutes, seconds, isLive, isUpcoming } =
    countdownMapping[campaignName as CampaignEnum];

  const isYou = useCallback(
    (username: string) =>
      accountData?.username
        ? accountData.username.toLowerCase() === username.toLowerCase()
        : false,
    [accountData]
  );

  const accountAllTimeData = useMemo(() => {
    const accountStats = accountStatistics?.account_statistics;
    const allTimeData = accountStats?.all_time;
    if (allTimeData) {
      return allTimeData;
    }
    return undefined;
  }, [accountStatistics?.account_statistics]);

  const accountFilterData = useMemo(() => {
    const accountStats = accountStatistics?.account_statistics;
    const filteredData = accountStats?.[LeaderboardFilterEnum.ALL_TIME];
    if (filteredData) {
      return filteredData;
    }
    return undefined;
  }, [accountStatistics?.account_statistics]);

  const memoizedVolumeLeaderboard = useMemo(() => {
    if (
      !volumeLeaderboard?.leaderboard ||
      (accountApiKeyState === AccountStateEnum.OK && !accountAllTimeData)
    )
      return [];

    let filteredLeaderboard = (
      volumeLeaderboard?.leaderboard
        ? volumeLeaderboard?.leaderboard[LeaderboardFilterEnum.ALL_TIME]
        : []
    ) as GetLeaderboard200ResponseLeaderboardDaily[];
    const username = accountFilterData?.username;
    if (!username) {
      return filteredLeaderboard;
    }
    if (accountFilterData) {
      // Check if the username exists in filteredLeaderboard
      const userInLeaderboard = filteredLeaderboard.find(
        (entry) => entry.username === username
      );

      // if it doesnt exist add to top of table
      if (!userInLeaderboard) {
        filteredLeaderboard = [accountFilterData, ...filteredLeaderboard];
      }
    }
    return filteredLeaderboard;
  }, [
    accountAllTimeData,
    accountApiKeyState,
    accountFilterData,
    volumeLeaderboard?.leaderboard,
  ]);

  const columns: ITableColumn<GetLeaderboard200ResponseLeaderboardDaily>[] =
    useMemo(() => {
      const cols: ITableColumn<GetLeaderboard200ResponseLeaderboardDaily>[] = [
        {
          title: campaignTranslations("rank"),
          accessor: "ranking",
          disableSortBy: true,
          Cell: ({ value, row }: any) => (
            <Align
              align={"left"}
              style={{
                color: isYou(row.original.username || "")
                  ? COLORS.blue.one
                  : TEXT_COLORS.three,
              }}
            >
              {value}
            </Align>
          ),
        },
        {
          title: campaignTranslations("trader"),
          accessor: "username",
          disableSortBy: true,
          Cell: ({ value, row }: any) => (
            <Align
              align={"left"}
              style={{
                color: isYou(row.original.username || "")
                  ? COLORS.blue.one
                  : TEXT_COLORS.two,
              }}
            >
              {value}
              {isYou(value || "") ? ` (${campaignTranslations("you")})` : ""}
            </Align>
          ),
        },
        {
          title: campaignTranslations("perp_volume"),
          accessor: "perp_volume",
          align: "right",
          Cell: ({ value, row }: any) => (
            <Align
              align={"right"}
              style={{
                color: isYou(row.original.username || "")
                  ? COLORS.blue.one
                  : TEXT_COLORS.one,
              }}
            >
              {currency(value).format()}
            </Align>
          ),
        },
      ];

      return cols;
    }, [campaignTranslations, isYou]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: memoizedVolumeLeaderboard,
        defaultColumn: {
          Header: DefaultHeaderForColumn,
          Cell: DefaultCellForColumn,
        },
        autoResetSortBy: false,
      } as any,
      useSortBy
    );

  return (
    <div>
      <EigenTermsModal
        show={showTermsModal}
        onHide={() => setShowTermsModal(false)}
        minPerpVolume={campaignDetails.minPerpVolume}
      />
      <BackButtonWrapper
        onClick={() => navigate(PageEndpointEnum.CAMPAIGNS)}
        isMobileScreen={isMobileScreen}
      >
        <LeftArrow />
        <div>{t("all_campaigns")}</div>
      </BackButtonWrapper>
      <LeaderboardHeader isMobileScreen={isMobileScreen}>
        <>
          <SOLVideoContainer
            isMobileScreen={isMobileScreen}
            style={{ marginBottom: 0 }}
          >
            <BackgroundVideoFX />
          </SOLVideoContainer>
          <DescriptionContainer>
            <LogoContainer size={120} borderWidth={4} isLive={isLive}>
              <img
                src={getAssetLogo(asset)}
                width={114}
                height={114}
                alt={asset}
              />
            </LogoContainer>
            <FlexWrapper gap={SPACING.three} alignItems="center">
              <CountdownContainer>
                {isLive || isUpcoming ? (
                  <>
                    <Label number="three" color={TEXT_COLORS.three}>
                      {isLive
                        ? `${t("end_of_epoch")} ${epoch}`
                        : t("time_till_campaign_starts")}
                    </Label>
                    <Countdown>
                      <Data number="two" color={TEXT_COLORS.one}>
                        {days}
                      </Data>
                      <Label number="three" color={TEXT_COLORS.three}>
                        {campaignTranslations(
                          days > 1 ? "days_short" : "day_short"
                        )}
                      </Label>
                    </Countdown>
                    <Countdown>
                      <Data number="two" color={TEXT_COLORS.one}>
                        {hours}
                      </Data>
                      <Label number="three" color={TEXT_COLORS.three}>
                        {campaignTranslations(
                          hours > 1 ? "hours_short" : "hour_short"
                        )}
                      </Label>
                    </Countdown>
                    <Countdown>
                      <Data number="two" color={TEXT_COLORS.one}>
                        {minutes}
                      </Data>
                      <Label number="three" color={TEXT_COLORS.three}>
                        {campaignTranslations(
                          minutes > 1 ? "minutes_short" : "minute_short"
                        )}
                      </Label>
                    </Countdown>
                    {!isMobileScreen && (
                      <Countdown>
                        <Data number="two" color={TEXT_COLORS.one}>
                          {seconds}
                        </Data>
                        <Label number="three" color={TEXT_COLORS.three}>
                          {campaignTranslations(
                            seconds > 1 ? "seconds_short" : "second_short"
                          )}
                        </Label>
                      </Countdown>
                    )}
                  </>
                ) : (
                  <Label number="three" color={COLORS.highlight.one}>
                    {campaignTranslations("campaign_over")}
                  </Label>
                )}
              </CountdownContainer>
              <FlexWrapper gap={SPACING.two} alignItems="center">
                <Label number="three" color={TEXT_COLORS.three}>
                  {t("total_weeth_deposits")}
                </Label>
                <Data number="two" color={TEXT_COLORS.one}>
                  {weETHData?.total_deposits
                    ? currency(
                        ethers.utils.formatUnits(
                          String(weETHData.total_deposits),
                          18
                        ),
                        { symbol: "" }
                      ).format()
                    : "-"}
                </Data>
              </FlexWrapper>
            </FlexWrapper>
          </DescriptionContainer>
        </>
      </LeaderboardHeader>
      <FlexWrapper
        flexColumn
        gap={SPACING.three}
        style={{
          padding: SPACING.three,
          display: "flex",
        }}
      >
        <Header number="five" color={TEXT_COLORS.one}>
          {t(`${campaignName.toLowerCase()}_title`, {
            asset,
          })}
        </Header>
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <SegmentControl
            config={{
              backgroundColor: COLORS.neutral.six,
              activeBackgroundColor: COLORS.neutral.five,
              borderRadius: "10px",
              button: {
                height: 32,
                fontSize: FONT_SIZE.one,
                px: SPACING.three,
              },
            }}
            hasDropdown
            segments={[
              {
                value: CampaignSectionEnum.DEPOSIT,
                display: campaignTranslations(CampaignSectionEnum.DEPOSIT),
                textColor:
                  campaignSection === CampaignSectionEnum.DEPOSIT
                    ? TEXT_COLORS.one
                    : TEXT_COLORS.three,
              },
              {
                value: CampaignSectionEnum.TRADE,
                display: campaignTranslations(CampaignSectionEnum.TRADE),
                textColor:
                  campaignSection === CampaignSectionEnum.TRADE
                    ? TEXT_COLORS.one
                    : TEXT_COLORS.three,
              },
            ]}
            value={campaignSection}
            onSelect={(value) =>
              setCampaignSection(value as CampaignSectionEnum)
            }
          />
        </div>
      </FlexWrapper>
      <FlexWrapper
        flexColumn
        gap={SPACING.five}
        style={{
          padding: SPACING.three,
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        {campaignSection === CampaignSectionEnum.DEPOSIT && (
          <>
            <Header number="seven" color={TEXT_COLORS.one}>
              {t("deposit_title")}
            </Header>
            <StyledBody number="three" color={TEXT_COLORS.two}>
              <span>{t("deposit_1")}</span>
              <span>{t("deposit_2")}</span>
              <span>{t("deposit_3")}</span>
              <List>
                <ListItem>
                  <span>1.</span>
                  <span>{t("deposit_4")}</span>
                </ListItem>
                <ListItem>
                  <span>2.</span>
                  <span>{t("deposit_5")}</span>
                </ListItem>
              </List>

              <span>{t("deposit_6")}</span>
              <List>
                <ListItem>
                  <span>1.</span>
                  <span>{t("deposit_7")}</span>
                </ListItem>
                <ListItem>
                  <span>2.</span>
                  <span>{t("deposit_8")}</span>
                </ListItem>
              </List>
            </StyledBody>
            <Body number="three" color={TEXT_COLORS.two}>
              {t("distribution")}{" "}
              <ExternalLinkText
                href="https://loyalty.eigenfoundation.org/"
                target="_blank"
                rel="noreferrer"
                color={TEXT_COLORS.one}
                inline
                hideArrow
              >
                loyalty.eigenfoundation.org
              </ExternalLinkText>
            </Body>
          </>
        )}
        {campaignSection === CampaignSectionEnum.TRADE && (
          <>
            <Header number="seven" color={TEXT_COLORS.one}>
              {t("trade_title")}
            </Header>
            <StyledBody number="three" color={TEXT_COLORS.two}>
              <span>{t("trade_1")}</span>
              <span>{t("trade_2")}</span>
              <span>{t("trade_3")}</span>
              <List>
                <ListItem>
                  <span>1.</span>
                  <span>{t("trade_4")}</span>
                </ListItem>
                <ListItem>
                  <span>2.</span>
                  <span>{t("trade_5")}</span>
                </ListItem>
              </List>
              <span>{t("trade_6")}</span>
              <List>
                <ListItem>
                  <span>1.</span>
                  <span>{t("trade_7")}</span>
                </ListItem>
                <ListItem>
                  <span>2.</span>
                  <span>{t("trade_8")}</span>
                </ListItem>
              </List>
            </StyledBody>
            <Body number="three" color={TEXT_COLORS.two}>
              {t("distribution")}{" "}
              <ExternalLinkText
                href="https://loyalty.eigenfoundation.org/"
                target="_blank"
                rel="noreferrer"
                color={TEXT_COLORS.one}
                inline
                hideArrow
              >
                loyalty.eigenfoundation.org
              </ExternalLinkText>
            </Body>
            <LeaderboardTableWrapper style={{ width: "100%" }}>
              <FlexWrapper flexColumn gap={SPACING.three}>
                <Body number="two">
                  {campaignTranslations("campaign_leaderboard")}
                </Body>
              </FlexWrapper>
              {
                // eslint-disable-next-line no-nested-ternary
                !volumeLeaderboard ? (
                  accountStatisticsLoading || volumeLeaderboardLoading ? (
                    <SpinnerContainerWrapper>
                      <Spinner />
                    </SpinnerContainerWrapper>
                  ) : (
                    <EmptyContentContainer
                      style={{ justifyContent: "flex-start" }}
                    >
                      {campaignTranslations("no_trades")}
                    </EmptyContentContainer>
                  )
                ) : (
                  <table {...getTableProps()}>
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        // eslint-disable-next-line react/jsx-key
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column: any) => (
                            // eslint-disable-next-line react/jsx-key
                            <TableHeaderCell
                              // We apply the sort properties to each column
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("Header")}
                            </TableHeaderCell>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row) => {
                        prepareRow(row);
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <LeaderboardTableRow {...row.getRowProps()}>
                            {row.cells.map((cell) => (
                              // eslint-disable-next-line react/jsx-key
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            ))}
                          </LeaderboardTableRow>
                        );
                      })}
                    </tbody>
                  </table>
                )
              }
            </LeaderboardTableWrapper>
          </>
        )}
      </FlexWrapper>
    </div>
  );
}

export default EigenCampaign;
