/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import { BigNumber, ethers, utils } from "ethers";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IERC20, IERC20__factory } from "../../codegen";
import { CollateralAssetResponse } from "../../codegen-api";
import {
  AllDepositWithdrawAssets,
  DepositWithdrawCollaterals,
  getCollateralERC20Addresses,
} from "../../constants/addresses";
import { ChainIdEnum } from "../../enums/chain";
import { splitSignature } from "../../utils/signing";
import { IWallet } from "../wallet/useWallet";
import { EIP2612_TYPE, validateEstimatedGas } from "./shared";
import { collateralAssetDecimals } from "../../utils/asset/assets";
import { supportedChainId } from "../../utils/wallet/connectors";
import { useWeb3Context } from "../../contexts/Web3Context";

const getERC20 = (
  provider: any,
  asset: AllDepositWithdrawAssets,
  chainId?: ChainIdEnum,
  useSigner: boolean = true
): IERC20 | undefined => {
  const signerOrProvider = useSigner ? provider?.getSigner() : provider;
  const { l1 } = getCollateralERC20Addresses(asset, chainId);
  // Add detailed logging
  console.log("loasdf: ERC20 Setup:", {
    asset,
    l1Address: l1,
    chainId,
    hasProvider: !!provider,
    hasSigner: !!signerOrProvider,
    providerNetwork: provider?._network,
    isSignerProvider: provider?.getSigner ? "yes" : "no",
  });

  if (signerOrProvider && l1) {
    const contract = IERC20__factory.connect(l1, signerOrProvider);

    return contract;
  }

  return undefined;
};

const canDepositWithPermit = (
  asset: AllDepositWithdrawAssets,
  chainId?: ChainIdEnum
) => {
  const validChainId =
    chainId === ChainIdEnum.ETH_MAINNET ||
    chainId === ChainIdEnum.LOCAL_TESTNET ||
    chainId === ChainIdEnum.SEPOLIA_TESTNET;
  return (
    (asset === CollateralAssetResponse.Usdc || asset === "AEVO") && validChainId
  );
};

const getDomainInfoForAssetWithPermit = (
  asset: AllDepositWithdrawAssets,
  chainId?: ChainIdEnum,
  verifyingContractAddress?: string
) => {
  if (!verifyingContractAddress || !chainId) {
    return undefined;
  }

  switch (asset) {
    case DepositWithdrawCollaterals.Usdc:
    case DepositWithdrawCollaterals.NativeUsdc:
      return {
        name: "USD Coin",
        version: chainId === ChainIdEnum.ETH_MAINNET ? "2" : "1",
        verifyingContract: verifyingContractAddress, // l1Contract.address,
        chainId,
      };
    case "AEVO":
      return {
        name: "USD Coin",
        version: chainId === ChainIdEnum.ETH_MAINNET ? "2" : "1",
        verifyingContract: verifyingContractAddress, // l1Contract.address,
        chainId,
      };
    default:
      return undefined;
  }
};

const useDepositERC20OrETH = (
  wallet: IWallet,
  asset: AllDepositWithdrawAssets
) => {
  const { account, provider, chainId } = wallet;

  const [l1Contract, setContract] = useState<IERC20>();
  const { provider: defaultProvider } = useWeb3Context();

  const [ethBalance, setEthBalance] = useState(BigNumber.from(0));
  const [erc20Balance, setErc20Balance] = useState(BigNumber.from(0));

  const balance = useMemo(() => {
    if (asset !== DepositWithdrawCollaterals.Weth) {
      return erc20Balance;
    }
    return ethBalance;
  }, [asset, erc20Balance, ethBalance]);

  const decimals = useMemo(() => collateralAssetDecimals[asset], [asset]);

  const l2Address = useMemo(() => {
    const { l2 } = getCollateralERC20Addresses(asset, chainId);
    return l2;
  }, [asset, chainId]);

  const useDefaultProvider = useMemo(() => {
    if (asset === "AEVO" || asset === "RBN") {
      return chainId !== supportedChainId;
    }
    return false;
  }, [asset, chainId]);

  useEffect(() => {
    const fetchBalances = async () => {
      const contract = getERC20(
        useDefaultProvider ? defaultProvider : provider,
        asset,
        useDefaultProvider ? supportedChainId : chainId,
        !useDefaultProvider
      );

      setContract(contract);

      if (!account) return;

      try {
        // Retrieve eth balance
        provider
          ?.getBalance(account)
          .then((bal) => {
            setEthBalance(bal);
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error(err);
          });

        setErc20Balance(BigNumber.from(0));

        if (contract) {
          // Try both with and without formatting to verify the raw response
          const rawBal = await contract.balanceOf(account);
          const formattedBal = utils.formatUnits(rawBal, decimals);

          setErc20Balance(rawBal);
        }
      } catch (err) {
        console.error("Error fetching balances:", {
          error: err,
        });
      }
    };

    fetchBalances();
  }, [asset, account, chainId, defaultProvider, provider, useDefaultProvider]);

  const getAssetPermitSignature = useCallback(
    async (
      spender: string,
      // Amount of USDC
      amount: string,
      deadline: number
    ) => {
      const domain = getDomainInfoForAssetWithPermit(
        asset as DepositWithdrawCollaterals,
        chainId,
        l1Contract?.address
      );

      if (!account || !l1Contract || !provider || !domain) {
        return undefined;
      }

      const approveMessage = {
        owner: account,
        spender,
        value: ethers.utils.parseUnits(amount, decimals).toString(),
        nonce: (await l1Contract.nonces(account)).toNumber(),
        deadline,
      };

      // eslint-disable-next-line no-underscore-dangle
      const approveAssetSignature = await provider
        .getSigner()
        ._signTypedData(domain, { Permit: EIP2612_TYPE }, approveMessage);

      if (approveAssetSignature) {
        const splitted = splitSignature(approveAssetSignature);
        return splitted;
      }
      return undefined;
    },
    [account, asset, chainId, decimals, l1Contract, provider]
  );

  const approveAsset = useCallback(
    async (
      spender: string,
      // Amount of USDC
      amountUSDC: string
    ) => {
      if (!account || !l1Contract || !provider) {
        return undefined;
      }

      const amountBN = utils.parseUnits(amountUSDC, decimals);
      const estGas = await validateEstimatedGas(
        l1Contract.estimateGas.approve(spender, amountBN),
        BigNumber.from("100000")
      );
      const tx = await l1Contract.approve(spender, amountBN, {
        gasLimit: estGas,
      });
      return tx.wait(1);
    },
    [account, decimals, l1Contract, provider]
  );

  const getAllowance = useCallback(
    async (spender: string) => {
      if (account) {
        if (asset === CollateralAssetResponse.Weth) {
          return String(Infinity);
        }

        const allowance = await l1Contract?.allowance(account, spender);
        if (allowance) {
          return utils.formatUnits(allowance, decimals);
        }
      }
      return "0";
    },
    [account, asset, decimals, l1Contract]
  );

  return {
    l1Contract,
    l2Address,
    decimals,
    ethBalance,
    userBalance: balance,
    getAssetPermitSignature:
      asset !== CollateralAssetResponse.Weth &&
      canDepositWithPermit(asset as DepositWithdrawCollaterals, chainId)
        ? getAssetPermitSignature
        : undefined,
    approveAsset,
    getAllowance,
  };
};

export default useDepositERC20OrETH;
