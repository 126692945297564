import { useTranslation } from "react-i18next";
import { IBaseModalProps, BaseModal } from "../../components/BaseModal";
import { TEXT_COLORS } from "../../constants/design/colors";
import { Body } from "../../constants/design/fontSize";
import { FlexWrapper, SPACING } from "../../constants/design/spacing";

function EigenTermsModal({ onHide, show, minPerpVolume }: IBaseModalProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "pages.CampaignsPage.TermsModal",
  });
  return (
    <BaseModal
      title={t("rules")}
      showHeader
      style={{
        width: 312,
      }}
      show={show}
      onHide={onHide}
    >
      <FlexWrapper gap={SPACING.three} flexColumn>
        <Body number="three" color={TEXT_COLORS.two}>
          <div>
            <span>{t("competition_rules")}</span>
            <ul style={{ paddingLeft: "20px" }}>
              <li>
                {t("rule_1_1")}
                <span style={{ color: TEXT_COLORS.one }}> {t("rule_1_2")}</span>
                , {t("rule_1_3")}
              </li>
              <li>
                {t("rule_2_1")}
                <span style={{ color: TEXT_COLORS.one }}> {t("rule_2_2")}</span>
                , {t("rule_2_3")}
              </li>
            </ul>
          </div>
        </Body>
        <Body number="three" color={TEXT_COLORS.two}>
          <div>
            <span>{t("eligibility_requirements")}</span>
            <ul style={{ paddingLeft: "20px" }}>
              <li>{t("requirement_1", { min_perp_volume: minPerpVolume })}</li>
            </ul>
          </div>
        </Body>
      </FlexWrapper>
    </BaseModal>
  );
}

export default EigenTermsModal;
